// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bJ_k4";
export var caseStudyBackground__lineColor = "bJ_kW";
export var caseStudyHead__imageWrapper = "bJ_kT";
export var caseStudyProjectsSection = "bJ_k5";
export var caseStudyQuote__bgLight = "bJ_k2";
export var caseStudyQuote__bgRing = "bJ_k1";
export var caseStudyQuote__bottomVideo = "bJ_lZ";
export var caseStudyVideo = "bJ_k7";
export var caseStudyVideo__ring = "bJ_k8";
export var caseStudy__bgDark = "bJ_kS";
export var caseStudy__bgLight = "bJ_kR";